import store from "@/store";
import { getQueryString, filtersToSearchItems } from "@/utils/paginator";

// eslint-disable-next-line no-unused-vars
const _countLoadList = async ($this) => {
  await $this.loadingCount++;
  console.log("__________", $this.loadingCount);
};

export async function loadList($this, requestUrl, logflag = "") {
  // await _countLoadList($this);

  try {
    $this.loading = true;
    const { page, itemsPerPage: limits } = $this.options;
    const queryString = getQueryString({
      limits,
      page,
      filters: $this.filters,
    });

    const res = await $this.authGet(`${requestUrl}${queryString}`);
    $this.items = res.data.data;
    $this.totalItems = Number(res.data.totalItems);
    $this.filters = res.data.filters;
    $this.searchItems = filtersToSearchItems($this.filters);
    if (logflag) {
      console.log(logflag, {
        msal: $this.$msal,
        page,
        limits,
        filters: $this.filters,
        queryString,
        requestUrl,
        totalItems: $this.totalItems,
        searchItems: $this.searchItems,
      });
    }
    $this.loading = false;
  } catch (e) {
    $this.processError(e);
  }
}

export function setDefaultHeaderAttr(headersArr, align = "") {
  return headersArr.map((header) =>
    Object.assign(header, {
      filterable: false,
      sortable: false,
      align,
    })
  );
}

export async function getPermissions($this, viewId) {
  try {
    // 백엔드에서 요청한 현재 화면에서 허용된 액션 목록
    const res = await $this.authGet(`/api/auth/perm/views/${viewId}`);
    const permissions = res.data.data || [];
    console.log("permissions: ", permissions);
    $this.permissions = permissions;
  } catch (e) {
    $this.processError(e);
  }
}

export function getAllowedActions($this, viewIds) {
  try {
    // Store에 저장된 현재 화면에서 허용된 액션 목록
    const allowedActions = [...viewIds].flatMap((viewId) =>
      store.getters.getViewAllowedActions(viewId));
    console.log("allowedActions: ", allowedActions);
    $this.permissions = allowedActions;
  } catch (e) {
    $this.processError(e);
  }
}
