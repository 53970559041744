// util for server side pagination

export function getQueryString(params) {
  const paramString = Object.entries(params)
    .filter(
      ([, param]) =>
        param !== undefined && param !== null && param.toString().trim() !== ""
    )
    .map(([key, param]) => `${key}=${param}`)
    .join("&");
  return paramString ? `?${paramString}` : "";
}

export function searchItemsToFilters(searchItems) {
  const filterString = encodeURIComponent(
    Object.entries(searchItems)
      .filter(
        ([, value]) =>
          value !== undefined &&
          value !== null &&
          value.toString().trim() !== ""
      )
      .map(([key, value]) => `${key}:${value}`)
      .join("+")
  );
  return filterString;
}

export function filtersToSearchItems(filters) {
  const searchItems = {};
  if (filters) {
    const filterArray = filters.split("+");
    filterArray.forEach((filterItem) => {
      const [key, value] = filterItem.split(":");
      searchItems[key] = value;
    });
  }
  return searchItems;
}
