import i18n from "@/i18n";

export function renderCorporateAccountStatus(status) {
  const statusMap = {
    1: "corporate-account-status.review-requested",
    2: "corporate-account-status.suitability-approved",
    3: "corporate-account-status.document-approved",
    4: "corporate-account-status.review-approved",
    5: "corporate-account-status.review-disapproved",
    6: "corporate-account-status.second-review-requested",
    7: "corporate-account-status.second-review-approved",
    8: "corporate-account-status.second-review-disapproved",
    9: "corporate-account-status.request-additional-withdrawal",
  };
  const statusString = statusMap[status]
    ? i18n.t(statusMap[status])
    : i18n.t("corporate-account-status.unknown-corporate-account-status");
  // console.log(statusString);
  return statusString;
}

/*
"unknown-admin-user-action-type": "Unknown",
"admin-user-action-request-corporate-account":"법인계정 전환 요청",
"admin-user-action-approve-corporate-account-suitability":"법인적합성 검토 승인",
"admin-user-action-approve-corporate-account-document":"제출서류 검토 승인",
"admin-user-action-disapprove-corporate-account":"1차 심사 거절",
"admin-user-action-second-approve-corporate-account":"고액 한도 심사 승인",
"admin-user-action-second-disapprove-corporate-account":"고액 한도 심사 거절",
"admin-user-action-request-corporate-account-limit":"법인계정 추가출금 증액 신청"
*/
export function renderAdminUserActionType(actionType) {
  const actionTypeMap = {
    11003: "admin-user-action-type.admin-user-action-request-corporate-account",
    11004: "admin-user-action-type.admin-user-action-approve-corporate-account-suitability",
    11005: "admin-user-action-type.admin-user-action-approve-corporate-account-document",
    11006: "admin-user-action-type.admin-user-action-disapprove-corporate-account",
    11007: "admin-user-action-type.admin-user-action-second-approve-corporate-account",
    11008: "admin-user-action-type.admin-user-action-second-disapprove-corporate-account",
    11009: "admin-user-action-type.admin-user-action-request-corporate-account-limit",
  };
  const actionTypeString = actionTypeMap[actionType]
    ? i18n.t(actionTypeMap[actionType])
    : i18n.t("admin-user-action-type.unknown-admin-user-action-type");
  // console.log(actionTypeString);
  return actionTypeString;
}

const corporateTypeMap = {
  1: "corporate-type.corporate-type-native-listed-exclude-new",
  2: "corporate-type.corporate-type-native-listed",
  3: "corporate-type.corporate-type-native-unlisted",
  4: "corporate-type.corporate-type-foreign-resident",
  5: "corporate-type.corporate-type-foreign-unresident",
  6: "corporate-type.corporate-type-nonprofit-native",
  7: "corporate-type.corporate-type-nonprofit-foreign",
  8: "corporate-type.corporate-type-etc",
};
export function renderCorporateType(type) {
  const typeString = corporateTypeMap[type]
    ? i18n.t(corporateTypeMap[type])
    : "";
  // console.log("in renderCorporateType,", type, typeString);
  return typeString;
}
export function getCorporateTypeItems() {
  return Object.keys(corporateTypeMap).map((key) => ({
    text: renderCorporateType(key),
    value: key,
  }));
}

const corporateStatusMap = {
  1: "corporate-status.corporate-status-open",
  2: "corporate-status.corporate-status-closed",
  3: "corporate-status.corporate-status-inactivated",
};
export function renderCorporateStatus(status) {
  const statusString = corporateStatusMap[status]
    ? i18n.t(corporateStatusMap[status])
    : "";
  // console.log("in renderCorporateStatus,", status, statusString);
  return statusString;
}
export function getCorporateStatusItems() {
  return Object.keys(corporateStatusMap).map((key) => ({
    text: renderCorporateStatus(key),
    value: key,
  }));
}

const corporateRelatedPersonTypeMap = {
  1: "corporate-related-person-type.corporate-related-person-type-presentative",
  2: "corporate-related-person-type.corporate-related-person-type-agent",
  3: "corporate-related-person-type.corporate-related-person-type-owner",
};
export function renderCorporateRelatedPersonType(type = 0) {
  const typeString = corporateRelatedPersonTypeMap[type]
    ? i18n.t(corporateRelatedPersonTypeMap[type])
    : "";
  // console.log("in renderCorporateRelatedPersonStatus,", type, typeString);
  return typeString;
}
export function getCorporateRelatedPersonTypeItems() {
  return Object.keys(corporateRelatedPersonTypeMap).map((key) => ({
    text: renderCorporateRelatedPersonType(key),
    value: key,
  }));
}

const corporateDocumentTypeMap = {
  0: "corporate-document-type.unknown-corporate-document-type",
  1: "corporate-document-type.corporate-document-type-corporate-application",
  2: "corporate-document-type.corporate-document-type-business-registration-certificate",
  3: "corporate-document-type.corporate-document-type-certified-copy-of-corporate-registration",
  4: "corporate-document-type.corporate-document-type-certificate-of-a-registered-seal",
  5: "corporate-document-type.corporate-document-type-representative-id",
  6: "corporate-document-type.corporate-document-type-agent-id",
  7: "corporate-document-type.corporate-document-type-power-of-attorney",
  8: "corporate-document-type.corporate-document-type-articles-of-association",
  9: "corporate-document-type.corporate-document-type-list-of-shareholders",
  10: "corporate-document-type.corporate-document-type-company-introduction",
  11: "corporate-document-type.corporate-document-type-request-reason",
  12: "corporate-document-type.corporate-document-type-fiddq",
  13: "corporate-document-type.corporate-document-type-financial-position",
  14: "corporate-document-type.corporate-document-type-business-registration-status",
  15: "corporate-document-type.corporate-document-type-company-information",
  16: "corporate-document-type.corporate-document-type-wlf-result",
  17: "corporate-document-type.corporate-document-type-cretop-report",
  18: "corporate-document-type.corporate-document-type-kyc-due-diligence",
};
export function renderCorporateDocumentType(type = 0) {
  const typeString = corporateDocumentTypeMap[type]
    ? i18n.t(corporateDocumentTypeMap[type])
    : "";
  // console.log("in renderCorporateDocumentStatus,", type, typeString);
  return typeString;
}
export function getCorporateDocumentTypeItems() {
  return Object.keys(corporateDocumentTypeMap).map((key) => ({
    text: renderCorporateDocumentType(key),
    value: key,
  }));
}

export async function loadCountryCodes($this) {
  try {
    const res = await $this.callGrpcPost("Admin", "FindCountryCodes");
    const parsedData = JSON.parse(res.data.data);
    const countryCodes = JSON.parse(parsedData.data);
    // console.log("in loadCountryCodes,", countryCodes);
    return countryCodes;
  } catch (e) {
    $this.processError(e);
  }
}
export function getCountryItems(countryCodes) {
  return Object.entries(countryCodes).map(([key, value]) => ({
    text: `${key} : ${value}`,
    value: key,
  }));
}

export async function loadCorporationAmlCodes($this) {
  try {
    const res = await $this.callGrpcPost("Admin", "FindCorporationAmlCodes");
    const parsedData = JSON.parse(res.data.data);
    const corporationAmlCodes = JSON.parse(parsedData.data);
    // console.log("in loadCorporationAmlCodes,", corporationAmlCodes);
    // return new Promise((resolve, reject) => {
    //   setTimeout(resolve, 5000, corporationAmlCodes);
    // });
    return corporationAmlCodes;
  } catch (e) {
    $this.processError(e);
  }
}

export function getOccupationCategoryItems(occupationCategoryCodes) {
  return Object.entries(occupationCategoryCodes).map(([key, value]) => ({
    text: `${key} : ${value}`,
    value: key,
  }));
}

export function getTradePurposeItems(tradePurposeCodes) {
  return Object.entries(tradePurposeCodes).map(([key, value]) => ({
    text: `${key} : ${value}`,
    value: key,
  }));
}

export function getFundSourceItems(fundSourceCodes) {
  return Object.entries(fundSourceCodes).map(([key, value]) => ({
    text: `${key} : ${value}`,
    value: key,
  }));
}

export function getCorporateOwnerSkipTypeItems(ownerSkipTypeCodes) {
  return Object.entries(ownerSkipTypeCodes).map(([key, value]) => ({
    label: value,
    value: key,
  }));
}

export function getCorporateOwnerTypeItems(ownerTypeCodes) {
  return Object.entries(ownerTypeCodes).map(([key, value]) => ({
    label: value,
    value: key,
  }));
}
