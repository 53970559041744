<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="refreshData">
            Refresh
          </v-btn>
        </v-card-actions>
      </v-col>

      <!-- 등록 버튼 -->
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn
            v-if="editEnabled"
            color="success"
            min-width="100"
            @click="openCorporateCreateForm()"
            >{{ $t("button-text.registration") }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <!-- 법인 목록 -->
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      :server-items-length="totalItems"
      :options.sync="options"
      dense
      multi-sort
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 100],
      }"
      class="elevation-1"
    >
      <template v-slot:item.firstUserId="{ item }">
        <span>{{ item.firstUserId }}</span>
        <span v-if="item.countUser > 1">&nbsp;({{ item.countUser }})</span>
      </template>

      <template v-slot:item.firstUserStatus="{ item }">
        <span v-if="item.firstUserInactivated">{{
          $t("user-status.inactivated")
        }}</span>
        <span v-else-if="item.firstUserDeactivated">{{
          $t("user-status.deactivated")
        }}</span>
        <span v-else>{{ $t("user-status.activated") }}</span>
      </template>

      <template v-if="isAvailableButton" v-slot:item.editButton="{ item }">
        <v-icon class="mr-2" @click="openCorporateEditForm(item.id)"
          >mdi-pencil
        </v-icon>
      </template>

      <template v-if="isAvailableButton" v-slot:item.readButton="{ item }">
        <v-icon class="mr-2" @click="openCorporateEditForm(item.id)"
          >mdi-file-document
        </v-icon>
      </template>

      <template
        v-if="isAvailableButton"
        v-slot:item.relatedPersonButton="{ item }"
      >
        <v-icon class="mr-2" @click="openRelatedPersonList(item.id)"
          >mdi-account-multiple-plus </v-icon
        ><span>{{ item.countRelatedPerson }}</span>
      </template>

      <template v-if="isAvailableButton" v-slot:item.uploadButton="{ item }">
        <v-icon class="mr-2" @click="openUploadDocumentForm(item.id)"
          >mdi-attachment
        </v-icon>
        <span title="count of attachment">{{ item.title }}</span>
      </template>

      <template v-slot:item.type="{ item }">
        <span :title="item.type">{{ renderType(item.type) }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <span :title="item.status">{{ renderStatus(item.status) }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toFormattedDateString(item.createdAt) }}
      </template>

      <template v-slot:item.updatedAt="{ item }">
        {{ toFormattedDateString(item.updatedAt) }}
      </template>
    </v-data-table>

    <!-- 유저ID 검색 -->
    <!-- START : user corporate -->
    <!--
      기존법인 계정의 추가를 위한 dialog
      이미 법인계정으로 전환된 경우에도 법인정보와 연결하기 위해 추가 액션이 필요
    -->
    <v-dialog v-model="dialogSearchUser" max-width="500">
      <v-card class="text-center" card-actions-padding="20">
        <v-card-title>{{ $t("user-id") }} {{ $t("search") }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="userCorporateForAddition.userId"
            disabled
          ></v-text-field>
          <v-text-field
            v-model="userCorporateForAddition.email"
            disabled
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-spacer />
          <v-btn color="default" @click="dialogSearchUser = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="success"
            @click="
              openConfirmAppendUserCorporate();
              dialogSearchUser = false;
            "
          >
            {{ $t("registration") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END : user corporate -->

    <!-- 법인 정보 상세 -->
    <!-- START : corporate (info) -->
    <v-form v-model="validCorporateForm">
      <v-dialog v-model="dialogCorporateForm" max-width="1280px">
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ $t("corporate-information") }} {{ $t("registration") }}
            </span>

            <v-spacer />

            <v-icon aria-label="Close" @click="dialogCorporateForm = false">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>

            <!-- 등록 -->
            <v-container v-if="isRegistration">
              <v-row>
                <!-- 대표ID 정보 -->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="corporateData.userId"
                    :label="$t('user-id')"
                    :rules="ruleRequired"
                    maxlength="20"
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-model="userCorporateForRegistration.email"
                    :label="`${$t('email-address')} ${$t('for-reference')}`"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- 유저ID 검색 for 대표ID -->
                <v-col cols="12" sm="5" md="5">
                  <v-text-field
                    v-model="userCorporateForRegistration.userId"
                    :label="$t('corporate-user-id-registration-description')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-btn color="info" @click="checkUserId()">{{
                    $t("button-text.find")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-container>

            <!-- 수정 -->
            <v-container v-if="!isRegistration">
              <v-row>
                <!-- 법인계정 목록 -->
                <v-col cols="12" sm="8" md="8">
                  <v-card class="px-5 py-3">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <colgroup>
                          <col style="width: *" />
                          <col style="width: *" />
                          <col style="width: *" />
                          <col style="width: 120px" />
                          <col style="width: *" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th scope="col">{{ $t("user-id") }}</th>
                            <th scope="col">{{ $t("email-address") }}</th>
                            <th scope="col">
                              {{ $t("corporate-related-person.type") }}
                            </th>
                            <th scope="col">
                              {{ $t("button-text.approve") }} /
                              {{ $t("button-text.remove") }}
                            </th>
                            <th scope="col">
                              {{ $t("approved-datetime") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in corporateData.userCorporates"
                            :key="item.userId"
                          >
                            <td :title="item.id">{{ item.userId }}</td>
                            <td>{{ item.userEmail }}</td>
                            <td>
                              <span v-if="item.isFirst">{{
                                $t("corporate-info.user-id")
                              }}</span>
                            </td>
                            <td>
                              <div v-if="item.isFirst">
                                <!-- 대표ID 승인 버튼 -->
                                <span
                                  v-if="isKycStatus(kycStatusMap.KYC_COMPLETE)"
                                  >{{ $t("kyc-status.kyc-complete") }}</span
                                >
                                <v-btn
                                  v-if="
                                    editEnabled &&
                                    isKycStatus(kycStatusMap.KYC_INCOMPLETE)
                                  "
                                  color="warning"
                                  small
                                  :title="`corporateAccountId: ${item.corporateAccountId}`"
                                  @click="
                                    openConfirmApproveCorporateAccount(
                                      item.id,
                                      item.corporateAccountId,
                                      true
                                    )
                                  "
                                  >{{ $t("button-text.approve") }}</v-btn
                                >
                                <v-btn
                                  v-if="
                                    editEnabled &&
                                    isKycStatus(
                                      kycStatusMap.KYC_EXPIRATION_GRACE_PERIOD
                                    )
                                  "
                                  color="warning"
                                  small
                                  @click="openConfirmKycRefresh(item.id)"
                                  >{{ $t("button-text.refresh") }}</v-btn
                                >
                                <span
                                  v-if="isKycStatus(kycStatusMap.KYC_EXPIRED)"
                                  >{{ $t("kyc-status.kyc-expired") }}</span
                                >
                                <v-btn
                                  v-if="
                                    editEnabled &&
                                    isKycStatus(kycStatusMap.KYC_EXPIRED)
                                  "
                                  color="warning"
                                  small
                                  :title="`corporateAccountId: ${item.corporateAccountId}`"
                                  @click="
                                    openConfirmApproveCorporateAccount(
                                      item.id,
                                      item.corporateAccountId,
                                      true
                                    )
                                  "
                                  >{{ $t("button-text.refresh") }}</v-btn
                                >
                              </div>
                              <div v-else-if="item.userEmail">
                                <!-- 추가계정 승인/제거 버튼-->
                                <v-btn
                                  v-if="!item.approvedAt && editEnabled"
                                  color="warning"
                                  class="mr-5"
                                  x-small
                                  :title="item.corporateAccountId"
                                  @click="
                                    openConfirmApproveCorporateAccount(
                                      item.id,
                                      item.corporateAccountId
                                    )
                                  "
                                  >{{ $t("button-text.approve") }}</v-btn
                                >
                                &nbsp;&nbsp;
                                <v-btn
                                  v-if="editEnabled"
                                  color="error"
                                  x-small
                                  @click="
                                    openConfirmRemoveUserCorporate(item.id)
                                  "
                                  >{{ $t("button-text.remove") }}</v-btn
                                >
                              </div>
                            </td>
                            <td>
                              {{ toFormattedDateString(item.approvedAt) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>

                <!-- 유저ID 검색 for 계정추가 -->
                <v-col
                  v-if="
                    DISABLED_FOR_APPEND_USER_CORPORATE &&
                    editEnabled &&
                    isKycStatus(kycStatusMap.KYC_COMPLETE)
                  "
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-text-field
                    v-model="userCorporateForAddition.userId"
                    :label="$t('corporate-user-id-addition-description')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="
                    DISABLED_FOR_APPEND_USER_CORPORATE &&
                    editEnabled &&
                    isKycStatus(kycStatusMap.KYC_COMPLETE)
                  "
                  cols="12"
                  sm="1"
                  md="1"
                >
                  <v-btn color="info" @click="checkUserIdForUpdate()">
                    {{ $t("button-text.append") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <!-- 법인 정보 폼 -->
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.nameKo"
                    :label="$t('corporate-info.name-ko')"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.nameEn"
                    :label="$t('corporate-info.name-en')"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateTypeSelect.select"
                    :items="corporateTypeSelect.items"
                    :label="$t('corporate-info.type')"
                    :rules="ruleRequiredForLength"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateDataType(
                        `${corporateTypeSelect.select.value}`
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateStatusSelect.select"
                    :items="corporateStatusSelect.items"
                    :label="$t('status')"
                    :rules="ruleRequiredForLength"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateDataStatus(
                        `${corporateStatusSelect.select.value}`
                      )
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.identifier"
                    :label="$t('corporate-info.identifier')"
                    :rules="ruleRequired"
                    maxlength="64"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.corporateIdentifier"
                    :label="$t('corporate-info.corporate-identifier')"
                    :rules="ruleRequired"
                    maxlength="64"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-menu
                    v-model="datePickerEstablishedDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="corporateData.corporateInput.establishedDate"
                        :label="$t('corporate-startdate')"
                        readonly
                        placeholder="YYYY-MM-DD"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="corporateData.corporateInput.establishedDate"
                      @input="datePickerEstablishedDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.establishedCountry"
                    :label="$t('corporate-info.established-country')"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateCountrySelect.select"
                    :items="corporateCountrySelect.items"
                    :label="`${$t('corporate-info.headquarter')}, ${$t(
                      'country'
                    )}`"
                    :rules="ruleRequiredForLength"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateDataCountry(
                        `${corporateCountrySelect.select.value}`
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.zipcode"
                    :label="`${$t('corporate-info.headquarter')}, ${$t(
                      'zipcode'
                    )}`"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="corporateData.corporateInput.address"
                    :label="`${$t('corporate-info.headquarter')}, ${$t(
                      'address'
                    )}`"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="corporateData.corporateInput.addressDetail"
                    :label="`${$t('corporate-info.headquarter')}, ${$t(
                      'detail'
                    )} ${$t('address')}`"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.phoneNumber"
                    :label="`${$t('corporate-info.headquarter')}, ${$t(
                      'phone-number'
                    )}`"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.faxNumber"
                    :label="`${$t('corporate-info.headquarter')}, ${$t(
                      'fax-number'
                    )}`"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateOfficeCountrySelect.select"
                    :items="corporateOfficeCountrySelect.items"
                    :label="`${$t('corporate-info.office')}, ${$t('country')}`"
                    :rules="ruleRequiredForLength"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateDataOfficeCountry(
                        `${corporateOfficeCountrySelect.select.value}`
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.officeZipcode"
                    :label="`${$t('corporate-info.office')}, ${$t('zipcode')}`"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="corporateData.corporateInput.officeAddress"
                    :label="`${$t('corporate-info.office')}, ${$t('address')}`"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="corporateData.corporateInput.officeAddressDetail"
                    :label="`${$t('corporate-info.office')}, ${$t(
                      'detail'
                    )} ${$t('address')}`"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.officePhoneNumber"
                    :label="`${$t('corporate-info.office')}, ${$t(
                      'phone-number'
                    )}`"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.officeFaxNumber"
                    :label="`${$t('corporate-info.office')}, ${$t(
                      'fax-number'
                    )}`"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-radio-group
                    v-model="corporateData.corporateInput.ownerSkipType"
                    :label="$t('corporate-info.owner-skip-type')"
                    :rules="ruleRequired"
                    @change="checkCorporateOwnerSkipTypeRadios"
                  >
                    <v-radio
                      v-for="(item1, index) in corporateOwnerSkipTypeRadios"
                      :key="index"
                      :label="item1.label"
                      :value="item1.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-radio-group
                    v-model="corporateData.corporateInput.ownerType"
                    :label="$t('corporate-info.owner-type')"
                    :disabled="!isUnknownOwnerSkipType"
                  >
                    <v-radio
                      v-for="(item2, index) in corporateOwnerTypeRadios"
                      :key="index"
                      :label="item2.label"
                      :value="item2.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="corporateData.corporateInput.ownerShareRate"
                    :label="`${$t('corporate-info.owner-share-rate')} (${$t(
                      'symbol.percent'
                    )})`"
                    :disabled="!isUnknownOwnerSkipType"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="
                      corporateData.corporateInput.isVirtualAssetProvider
                    "
                    :label="$t('corporate-info.is-virtual-asset-provider')"
                    class="mt-0"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateTradePurposeSelect.select"
                    :items="corporateTradePurposeSelect.items"
                    :label="$t('corporate-info.trade-purpose')"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateDataTradePurpose(
                        `${corporateTradePurposeSelect.select.value}`
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateFundSourceSelect.select"
                    :items="corporateFundSourceSelect.items"
                    :label="$t('corporate-info.fund-source')"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateDataFundSource(
                        `${corporateFundSourceSelect.select.value}`
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="corporateData.corporateInput.establishedPurpose"
                    :label="$t('corporate-info.established-purpose')"
                    :disabled="establishedPurposeDisabled"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateOccupationCategorySelect.select"
                    :items="corporateOccupationCategorySelect.items"
                    :label="$t('corporate-category-item')"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateDataOccupationCategory(
                        `${corporateOccupationCategorySelect.select.value}`
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="
                      corporateData.corporateInput.occupationCategoryDetail
                    "
                    :label="$t('corporate-info.occupation-category-detail')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="corporateData.corporateInput.homepageUrl"
                    :label="`${$t('homepage')}, ${$t('url')}`"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="corporateData.corporateInput.remark"
                    :label="$t('remark')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="editEnabled">
            <v-btn
              v-if="!getIsProd() && !isEditModeForCorporateData()"
              color="gray darken-1"
              @click="setTestNewCorporateData()"
              >TEST FORM
            </v-btn>
            <v-btn
              v-if="!getIsProd() && !isEditModeForCorporateData()"
              color="gray darken-1"
              @click="clearNewCorporateData()"
              >CLEAR
            </v-btn>
            <v-btn
              v-if="isEditModeForCorporateData()"
              color="gray"
              @click="
                resetCurrentCorporateData(corporateData.corporateInput.id)
              "
              >RESET
            </v-btn>

            <v-spacer />

            <v-btn color="gray darken-1" @click="dialogCorporateForm = false"
              >{{ $t("close") }}
            </v-btn>
            <v-btn
              v-if="!isEditModeForCorporateData()"
              color="success darken-1"
              :disabled="!validCorporateForm"
              @click="storeCorporate()"
              >{{ $t("registration") }}
            </v-btn>
            <v-btn
              v-if="isEditModeForCorporateData()"
              color="success"
              :disabled="!validCorporateForm"
              @click="updateCorporate(corporateData.corporateInput.id)"
              >{{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <!-- END : corporate (info) -->

    <!-- START : corporate related person -->
    <!-- 법인 관련인 목록 -->
    <v-dialog v-model="dialogRelatedPersonList" max-width="1280px">
      <v-card class="text-center">
        <v-card-title>
          <span class="headline">
            {{ $t("corporate-related-person.title") }}
          </span>

          <v-spacer />

          <v-icon aria-label="Close" @click="dialogRelatedPersonList = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="relatedPersonHeaders"
              :items="relatedPersonItems"
              :loading="loadingDialog"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item.type="{ item }">
                <v-icon :title="item.type">
                  {{ renderRelatedPersonType(item.type) }}
                </v-icon>
              </template>

              <template v-slot:item.createdAt="{ item }">
                {{ toFormattedDateString(item.createdAt) }}
              </template>

              <template v-slot:item.updatedAt="{ item }">
                {{ toFormattedDateString(item.updatedAt) }}
              </template>

              <template v-slot:item.editButton="{ item }">
                <v-icon
                  class="mr-2"
                  @click="openCorporateRelatedPersonEditForm(item.id)"
                  >mdi-pencil
                </v-icon>
              </template>

              <template v-slot:item.readButton="{ item }">
                <v-icon
                  class="mr-2"
                  @click="openCorporateRelatedPersonEditForm(item.id)"
                  >mdi-file-document
                </v-icon>
              </template>

              <template v-slot:item.deleteButton="{ item }">
                <v-icon
                  class="mr-2"
                  @click="openCorporateRelatedPersonDeleteForm(item.id)"
                  >mdi-trash-can-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="editEnabled">
          <v-spacer />
          <v-btn
            color="success darken-1"
            @click="openCorporateRelatedPersonCreateForm()"
            >{{ $t("registration") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 법인 관련인 폼 -->
    <v-form v-model="validRelatedPersonForm">
      <v-dialog v-model="dialogRelatedPersonForm" max-width="1280px">
        <v-card class="text-center">
          <v-card-title>
            <span class="headline">
              {{ $t("corporate-related-person.title") }}
              {{ $t("registration") }} / {{ $t("edit") }}
            </span>

            <v-spacer />

            <v-icon aria-label="Close" @click="dialogRelatedPersonForm = false">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateRelatedPersonTypeSelect.select"
                    :items="corporateRelatedPersonTypeSelect.items"
                    :label="$t('corporate-related-person.type')"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateRelatedPersonType(
                        `${corporateRelatedPersonTypeSelect.select.value}`
                      )
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.nameKo"
                    :label="$t('corporate-related-person.name-ko')"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.nameEn"
                    :label="$t('corporate-related-person.name-en')"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-menu
                    v-model="datePickerBirthdate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="relatedPersonData.relatedPersonInput.birthdate"
                        :label="$t('birthdate')"
                        readonly
                        placeholder="YYYY-MM-DD"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="relatedPersonData.relatedPersonInput.birthdate"
                      @input="datePickerBirthdate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-radio-group
                    v-model="relatedPersonData.relatedPersonInput.gender"
                    row
                    class="ml-4 mt-0"
                  >
                    <v-radio :label="$t('gender-male')" value="1" />
                    <v-radio :label="$t('gender-female')" value="0" />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.email"
                    :label="$t('email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.phoneNumber"
                    :label="$t('phone-number')"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.identifier"
                    :label="$t('corporate-related-person.identifier')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="
                      corporateRelatedPersonCitizenshipCountrySelect.select
                    "
                    :items="
                      corporateRelatedPersonCitizenshipCountrySelect.items
                    "
                    :label="$t('citizenship-country')"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateRelatedPersonCitizenshipCountry(
                        `${corporateRelatedPersonCitizenshipCountrySelect.select.value}`
                      )
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-model="corporateRelatedPersonCountrySelect.select"
                    :items="corporateRelatedPersonCountrySelect.items"
                    :label="`${$t('corporate-related-person.resident')}, ${$t(
                      'country'
                    )}`"
                    item-text="text"
                    item-value="value"
                    return-object
                    @change="
                      changeCorporateRelatedPersonCountry(
                        `${corporateRelatedPersonCountrySelect.select.value}`
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.zipcode"
                    :label="`${$t('corporate-related-person.resident')}, ${$t(
                      'zipcode'
                    )}`"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.address"
                    :label="`${$t('corporate-related-person.resident')}, ${$t(
                      'address'
                    )}`"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="relatedPersonData.relatedPersonInput.addressDetail"
                    :label="`${$t('corporate-related-person.resident')}, ${$t(
                      'detail'
                    )} ${$t('address')}`"
                    :rules="ruleRequired"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions v-if="editEnabled">
            <v-btn
              v-if="!getIsProd() && !isEditModeForRelatedPerson()"
              color="gray darken-1"
              @click="setTestRelatedPersonForm()"
              >FILL TEST FORM
            </v-btn>
            <v-btn
              v-if="!getIsProd() && !isEditModeForRelatedPerson()"
              color="gray darken-1"
              @click="clearNewRelatedPersonForm()"
              >CLEAR
            </v-btn>
            <v-btn
              v-if="isEditModeForRelatedPerson()"
              color="gray darken-1"
              @click="resetRelatedPersonData()"
              >RESET
            </v-btn>

            <v-spacer />

            <v-btn
              color="gray darken-1"
              @click="
                dialogRelatedPersonForm = false;
                dialogRelatedPersonList = true;
              "
              >{{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-if="!isEditModeForRelatedPerson()"
              color="success darken-1"
              :disabled="!validRelatedPersonForm"
              @click="storeCorporateRelatedPerson()"
              >{{ $t("registration") }}
            </v-btn>
            <v-btn
              v-if="isEditModeForRelatedPerson()"
              color="success darken-1"
              :disabled="!validRelatedPersonForm"
              @click="updateCorporateRelatedPerson()"
              >{{ $t("edit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <!-- 법인 관련인 삭제 확인 -->
    <v-dialog v-model="dialogRelatedPersonDelete" max-width="400">
      <v-card class="text-center">
        <v-card-title>
          {{ $t("corporate-related-person.title") }} {{ $t("delete") }}

          <v-spacer />

          <v-icon aria-label="Close" @click="dialogRelatedPersonDelete = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>{{ $t("confirm-delete") }}<br /> </v-card-text>

        <v-card-actions>
          <v-container>
            <v-row>
              <v-col>
                <v-btn @click="closeCorporateRelatedPersonDeleteForm()">
                  {{ $t("close") }}
                </v-btn>
                <v-btn color="error" @click="deleteCorporateRelatedPerson()">
                  {{ $t("delete") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END : corporate related person -->

    <!-- START : check approval -->
    <v-dialog v-model="dialogCheckApproval" max-width="500">
      <v-card
        class="text-center"
        :loading="inProgressApproval"
        card-actions-padding="20"
      >
        <v-card-title v-html="approveCorporateAccountTitle"></v-card-title>

        <v-card-text v-html="approveCorporateAccountResult"></v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn v-if="inProgressApproval" disabled color="error">
            {{ $t("in-progress") }}
          </v-btn>
          <v-btn
            v-if="!inProgressApproval"
            color="error"
            @click="dialogCheckApproval = false"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END : check approval -->

    <!-- START : corporate account -->
    <v-dialog v-model="dialogCorporateAccountList" max-width="800">
      <v-card class="text-center" card-actions-padding="20">
        <v-card-title>
          {{ $t("page.corporate-account-list") }}
          {{ $t("button-text.request") }}
          {{ $t("list") }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="dialogCorporateAccountList = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="corporateAccountHeaders"
            :items="corporateAccountItems"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item.dailyWithdrawalLimit="{ item }">
              <span>{{ toMoneyString(item.dailyWithdrawalLimit) }}</span>
            </template>
            <template v-slot:item.updatedAt="{ item }">
              {{ toFormattedDateString(item.updatedAt) }}
            </template>
            <template v-slot:item.selectButton="{ item }">
              <v-btn
                class="success"
                small
                @click="selectCorporateAccount(item)"
                >{{ $t("select") }}</v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- END : corporate account -->

    <!-- START : corporate document -->
    <v-dialog v-model="dialogDocument" max-width="1000" max-height="800">
      <v-card
        :loading="inProgressUpload"
        class="text-center"
        card-actions-padding="20"
      >
        <v-card-title>
          <span class="headline">
            {{ $t("corporate-document.title") }}
          </span>

          <v-spacer />

          <v-icon aria-label="Close" @click="dialogDocument = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <!-- 법인 서류 등록 -->
        <v-card-text v-if="documentUpdateEnabled">
          <v-container>
            <v-row>
              <v-col cols="12" sm="2" md="2">
                {{ $t("corporate-document.title") }}
                {{ $t("registration") }}
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="corporateDocumentTypeSelect.select"
                  :items="corporateDocumentTypeSelect.items"
                  :label="$t('corporate-document.type')"
                  :disabled="!!inProgressUpload"
                  item-text="text"
                  item-value="value"
                  return-object
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-file-input
                  v-model="files"
                  color="info accent-4"
                  :label="$t('corporate-document.select-file')"
                  :disabled="!!inProgressUpload"
                  prepend-icon=""
                  outlined
                  multiple
                  :display-size="1000"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip color="info accent-4" dark label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-btn
                  :disabled="!!inProgressUpload"
                  color="success"
                  @click="uploadDocument(corporateData.corporateId)"
                >
                  UPLOAD
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- 법인 서류 목록 -->
        <v-card-text>
          <v-data-table
            :headers="corporateDocumentHeaders"
            :items="corporateDocumentItems"
            :loading="loadingDialog"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td :title="item.id">{{ index + 1 }}</td>
                <td>{{ renderDocumentType(item.type) }}</td>
                <td>
                  <a @click="openAttachment(item.id)">{{ item.filename }}</a>
                </td>
                <td>{{ toFormattedDateString(item.createdAt) }}</td>
                <td>
                  <v-icon
                    v-if="documentUpdateEnabled"
                    class="mr-2"
                    @click="openConfirmRemoveCorporateDocument(item.id)"
                    >mdi-trash-can-outline
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="gray darken-1" @click="dialogDocument = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END : corporate document -->

    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import constants from "./constants";
import {
  loadList,
  setDefaultHeaderAttr,
  getAllowedActions,
} from "@/utils/load-data";
import {
  renderCorporateType,
  getCorporateTypeItems,
  renderCorporateStatus,
  getCorporateStatusItems,
  loadCountryCodes,
  getCountryItems,
  loadCorporationAmlCodes,
  getCorporateOwnerSkipTypeItems,
  getCorporateOwnerTypeItems,
  getTradePurposeItems,
  getFundSourceItems,
  getOccupationCategoryItems,
  renderCorporateRelatedPersonType,
  getCorporateRelatedPersonTypeItems,
  renderCorporateDocumentType,
  getCorporateDocumentTypeItems,
} from "@/utils/render-status";

const ViewIds = [constants.VIEW_CORPORATE, constants.VIEW_CORPORATE_DOCUMENT];
const Actions = {
  create: "admin:Corporate:Create",
  edit: "admin:Corporate:Edit",
  documentUpdate: "admin:CorporateDocument:Update",
};

export default {
  data: () => ({
    loading: true,
    loadingDialog: false,

    validSearchUser: false,
    validCorporateForm: false,
    validRelatedPersonForm: false,

    dialogSearchUser: false,
    dialogCorporateForm: false,
    dialogRelatedPersonList: false,
    dialogRelatedPersonForm: false,
    dialogRelatedPersonDelete: false,
    dialogCheckApproval: false,
    dialogDocument: false,
    dialogCorporateAccountList: false,

    // common
    isRegistration: false,
    isAvailableButton: false,
    countryCodes: {},

    // for search user
    userCorporateForRegistration: {},
    userCorporateForAddition: {},

    // for corporate
    options: {},
    itemsPerPage: 10,
    totalItems: 0,
    items: [],
    files: [],
    searchItems: {
      userId: null,
    },
    filters: "",
    corporateData: {
      corporateInput: {},
      userCorporates: [],
    },
    corporationAmlCodes: {},
    corporateTypeSelect: {},
    corporateStatusSelect: {},
    datePickerEstablishedDate: false, // corporateData.corporateInput.establishedDate
    corporateCountrySelect: {},
    corporateOfficeCountrySelect: {},
    isUnknownOwnerSkipType: false,
    corporateOwnerSkipTypeRadios: [],
    corporateOwnerTypeRadios: [],
    corporateTradePurposeSelect: {},
    corporateFundSourceSelect: {},
    corporateOccupationCategorySelect: {},
    establishedPurposeDisabled: false,

    // for corporate related person
    relatedPersonItems: [], // for list
    relatedPersonData: {
      relatedPersonInput: {},
    },
    corporateRelatedPersonTypeSelect: {},
    datePickerBirthdate: false, // relatedPersonData.relatedPersonInput.birthdate
    corporateRelatedPersonCitizenshipCountrySelect: {},
    corporateRelatedPersonCountrySelect: {},

    // for check approval
    inProgressApproval: null,
    approveCorporateAccountTitle: null,
    approveCorporateAccountResult: null,

    // for corporate account
    corporateAccountItems: [],

    // for cororate document
    corporateDocumentItems: [], // for list
    corporateDocumentData: {},
    corporateDocumentTypeSelect: {},
    inProgressUpload: null,

    // for KYC
    firstUserKyc: {},
    kycStatusMap: {
      KYC_INCOMPLETE: 1, // 미완료
      KYC_COMPLETE: 2, // 완료
      KYC_EXPIRATION_GRACE_PERIOD: 3, // 만료유예
      KYC_EXPIRED: 4, // 만료
    },
    DISABLED_FOR_APPEND_USER_CORPORATE: true, // GOP-22218

    // other
    rules: {
      required: (v) => !!v || "Required",
      requiredForLength: (v) =>
        (!!v && Object.keys(v).length > 0) || "Required",
    },
    snackbar: false,
    notificationMessage: "",
    permissions: [],
  }),
  computed: {
    isProd() {
      return this.getIsProd();
    },
    headers() {
      const headersArr = [
        {
          text: this.$t("id"),
          value: "id",
        },
        {
          text: this.$t("corporate-name-ko"),
          value: "nameKo",
        },
        {
          text: this.$t("corporate-identifier"),
          value: "identifier",
        },
        {
          text: this.$t("user-id"),
          value: "firstUserId",
        },
        {
          text: this.$t("email-address"),
          value: "firstUserEmail",
        },
        {
          text: this.$t("user-status.title"),
          value: "firstUserStatus",
        },
      ];

      // conditional column by update permission
      if (this.editEnabledHeaderOnly) {
        headersArr.push({
          text: this.$t("edit"),
          value: "editButton",
        });
      } else {
        headersArr.push({
          text: this.$t("read"),
          value: "readButton",
        });
      }

      headersArr.push(
        {
          text: this.$t("corporate-related-person.title"),
          value: "relatedPersonButton",
        },
        {
          text: this.$t("corporate-document.title"),
          value: "uploadButton",
        },
        {
          text: this.$t("corporate-info.type"),
          value: "type",
        },
        {
          text: this.$t("status"),
          value: "status",
        },
        {
          text: this.$t("created-datetime"),
          value: "createdAt",
        },
        {
          text: this.$t("updated-datetime"),
          value: "updatedAt",
        }
      );

      return setDefaultHeaderAttr(headersArr);
    },

    editEnabled() {
      return (
        this.permissions.indexOf(Actions.edit) >= 0 && this.isAvailableButton
      );
    },

    editEnabledHeaderOnly() {
      return this.permissions.indexOf(Actions.edit) >= 0;
    },

    documentUpdateEnabled() {
      return (
        this.permissions.indexOf(Actions.edit) >= 0 ||
        this.permissions.indexOf(Actions.documentUpdate) >= 0
      );
    },

    renderType() {
      return (type) => renderCorporateType(type);
    },

    renderStatus() {
      return (status) => renderCorporateStatus(status);
    },

    relatedPersonHeaders() {
      const headersArr = [
        {
          text: this.$t("corporate-related-person.type"),
          value: "type",
        },
        {
          text: this.$t("corporate-related-person.name-ko"),
          value: "nameKo",
        },
        {
          text: this.$t("email"),
          value: "email",
        },
        {
          text: this.$t("phone-number"),
          value: "phoneNumber",
        },
        {
          text: this.$t("created-datetime"),
          value: "createdAt",
        },
        {
          text: this.$t("updated-datetime"),
          value: "updatedAt",
        },
      ];

      // conditional column by update permission
      if (this.editEnabledHeaderOnly) {
        headersArr.push(
          {
            text: `${this.$t("edit")}`,
            value: "editButton",
          },
          {
            text: `${this.$t("delete")}`,
            value: "deleteButton",
          }
        );
      } else {
        headersArr.push({
          text: `${this.$t("read")}`,
          value: "readButton",
        });
      }

      return setDefaultHeaderAttr(headersArr);
    },

    renderRelatedPersonType() {
      return (type) => renderCorporateRelatedPersonType(type);
    },

    isEditModeForCorporateData() {
      return () => !!this.corporateData.corporateId;
    },

    isEditModeForRelatedPerson() {
      return () => !!this.relatedPersonData.corporateRelatedPersonId;
    },

    corporateAccountHeaders() {
      const headersArr = [
        {
          text: this.$t("id"),
          value: "id",
        },
        {
          text: this.$t("email-address"),
          value: "user.email",
        },
        {
          text: this.$t("withdrawal-limit.daily"),
          value: "dailyWithdrawalLimit",
        },
        {
          text: this.$t("updated-datetime"),
          value: "updatedAt",
        },
        {
          text: this.$t("select"),
          value: "selectButton",
        },
      ];
      return setDefaultHeaderAttr(headersArr);
    },

    corporateDocumentHeaders() {
      const headersArr = [
        {
          text: this.$t("id"),
          value: "id",
        },
        {
          text: this.$t("corporate-document.type"),
          value: "type",
        },
        {
          text: this.$t("corporate-document.filename"),
          value: "filename",
        },
        {
          text: this.$t("created-datetime"),
          value: "createdAt",
        },
      ];

      // conditional column by update permission
      if (this.documentUpdateEnabled) {
        headersArr.push({
          text: `${this.$t("delete")}`,
          value: "deleteButton",
        });
      }

      return setDefaultHeaderAttr(headersArr, "center");
    },

    renderDocumentType() {
      return (type) => renderCorporateDocumentType(type);
    },

    ruleRequired() {
      return [this.rules.required];
    },
    ruleRequiredForLength() {
      return [this.rules.requiredForLength];
    },

    isKycStatus() {
      return (kycStatus) => this.firstUserKyc.kycStatus === kycStatus;
    },
  },

  watch: {
    options: {
      async handler() {
        await this.loadCorporates();
      },
      deep: true,
    },
  },
  async mounted() {
    await Promise.all([
      getAllowedActions(this, ViewIds),
      this.initCorporationAmlCodes(),
      this.initCountryCodes(),
    ]).then(() => {
      this.initInputElements("MOUNTED");
      this.isAvailableButton = true;
      if (this.$route.params.id) {
        this.openCorporateCreateForm(this.$route.params.id);
      }
    });
  },
  methods: {
    clearFilters() {
      this.filters = null;
    },

    processError(e) {
      const { response } = e;
      if (response) {
        this.showNotification(response.data);
      } else {
        console.error(e);
        this.showNotification(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },

    async initCorporationAmlCodes() {
      this.corporationAmlCodes = await loadCorporationAmlCodes(this);
    },

    async initCountryCodes() {
      this.countryCodes = await loadCountryCodes(this);
    },

    initInputElements(time = null) {
      console.log("INIT INPUT ELEMENTS", time);
      this.setCorporateTypeSelect();
      this.setCorporateStatusSelect();
      this.setCorporateOwnerSkipTypeRadios();
      this.setCorporateOwnerTypeRadios();
      this.setCorporateTradePurposeSelect();
      this.setCorporateFundSourceSelect();
      this.setCorporateOccupationCategorySelect();
      this.setCountryCodes();
      this.setCorporateRelatedPersonTypeSelect();
      this.setCorporateDocumentTypeSelect();
    },

    // START script : corporate
    async refreshData() {
      await this.loadCorporates(true);
      const refreshedPath = "/corporate/corporate-info";
      const currentPath = this.$route.path;
      if (refreshedPath !== currentPath) {
        await this.$router.push(refreshedPath);
      }
    },

    async loadCorporates(isRefresh = false) {
      if (isRefresh) {
        this.clearFilters();
      }
      await loadList(this, "/api/corporate", "in loadCorporates,");
    },

    setZeroValues(zeroValueData = {}) {
      Object.entries(zeroValueData).map(([key, value]) => {
        this.corporateData.corporateInput[key] = value ? value.toString() : "0";
      });
    },

    async loadCorporate(corporateId) {
      Object.assign(this.corporateData, { userCorporates: [] });
      this.userCorporateForRegistration = {};
      this.userCorporateForAddition = {};
      try {
        const requestUrl = `/api/corporate/${corporateId}`;
        const res = await this.authGet(requestUrl);

        this.firstUserKyc = res.data.firstUserKyc;

        Object.assign(this.corporateData, {
          corporateId,
          corporateInput: res.data.corporate,
          userCorporates: res.data.userCorporates,
        });
        this.setForcedData();

        // prioritized set
        this.setZeroValues({
          type: res.data.corporate.type,
          ownerSkipType: res.data.corporate.ownerSkipType,
          ownerType: res.data.corporate.ownerType,
        });

        this.setCorporateTypeSelect(this.corporateData.corporateInput.type);
        this.setCorporateStatusSelect(this.corporateData.corporateInput.status);
        this.setCorporateTradePurposeSelect(
          this.corporateData.corporateInput.tradePurpose
        );
        this.setCorporateFundSourceSelect(
          this.corporateData.corporateInput.fundSource
        );
        this.setCorporateOccupationCategorySelect(
          this.corporateData.corporateInput.occupationCategory
        );

        this.setCountryCodes({
          corporateCountry: this.corporateData.corporateInput.country,
          corporateOfficeCountry: this.corporateData.corporateInput
            .officeCountry,
        });

        this.setUnknownOwnerSkipType();
      } catch (e) {
        this.processError(e);
      }
    },

    setForcedData() {
      Object.assign(this.corporateData.corporateInput, {
        establishedCountry: "KR", // fixed established country
      });
    },

    openCorporateCreateForm(userId) {
      this.userCorporateForRegistration = {};
      this.userCorporateForAddition = {};
      try {
        this.corporateData = {
          userId,
          corporateInput: {},
        };
        this.setForcedData();
        this.initInputElements("OPEN CORPORATE CREATE FORM");
      } catch (e) {
        this.processError(e);
      }
      this.dialogCorporateForm = true;
      this.isRegistration = true;
    },

    async openCorporateEditForm(corporateId) {
      try {
        await this.loadCorporate(corporateId);
      } catch (e) {
        this.processError(e);
      }
      this.dialogCorporateForm = true;
      this.isRegistration = false;
    },

    fillForNullable() {
      if (!this.corporateData.corporateInput.ownerShareRate) {
        this.corporateData.corporateInput.ownerShareRate = "0";
      }
    },

    async storeCorporate() {
      try {
        const userId = this.corporateData.userId;
        this.fillForNullable();
        const formData = this.corporateData;

        // console.log("before store,", formData);
        const res = await this.authPost(`/api/corporate/${userId}`, formData);
        // console.log("after store,", res);
        if (res.data.result) {
          await this.refreshData();
          this.dialogCorporateForm = false;
          this.showNotification("Stored");
        }
      } catch (e) {
        this.processError(e);
      }
    },

    async updateCorporate(corporateId) {
      try {
        this.fillForNullable();
        const formData = {
          corporateId,
          corporateInput: this.corporateData.corporateInput,
        };

        // console.log("before update,", formData);
        const res = await this.authPut(
          `/api/corporate/${corporateId}`,
          formData
        );
        // console.log("after update,", res);
        if (res.data.result) {
          await this.refreshData();
          this.dialogCorporateForm = false;
          this.showNotification("Updated");
        }
      } catch (e) {
        this.processError(e);
      }
    },

    async resetCurrentCorporateData(corporateId) {
      await this.loadCorporate(corporateId);
    },

    setEstablishedPurposeDisabled(selectedValue) {
      this.establishedPurposeDisabled = ["6", "7"].indexOf(selectedValue) > -1;
      if (this.establishedPurposeDisabled) {
        Object.assign(this.corporateData.corporateInput, {
          establishedPurpose: "",
        });
      }
    },

    changeCorporateDataType(selectedValue) {
      this.corporateData.corporateInput.type = selectedValue;
      this.setEstablishedPurposeDisabled(selectedValue);
    },

    changeCorporateDataStatus(selectedValue) {
      this.corporateData.corporateInput.status = selectedValue;
    },

    changeCorporateDataCountry(selectedValue) {
      this.corporateData.corporateInput.country = selectedValue;
    },

    changeCorporateDataOfficeCountry(selectedValue) {
      this.corporateData.corporateInput.officeCountry = selectedValue;
    },

    changeCorporateDataTradePurpose(selectedValue) {
      this.corporateData.corporateInput.tradePurpose = selectedValue;
    },

    changeCorporateDataFundSource(selectedValue) {
      this.corporateData.corporateInput.fundSource = selectedValue;
    },

    setCorporateTypeSelect(currentValue = null) {
      Object.assign(this.corporateTypeSelect, {
        select: currentValue
          ? {
              text: renderCorporateType(currentValue),
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getCorporateTypeItems(),
      });
    },

    setCorporateStatusSelect(currentValue = null) {
      Object.assign(this.corporateStatusSelect, {
        select: currentValue
          ? {
              text: renderCorporateStatus(currentValue),
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getCorporateStatusItems(),
      });
    },

    setCountryCodes(countryFields = {}) {
      this.setCorporateCountrySelect(countryFields.corporateCountry);
      this.setCorporateOfficeCountrySelect(
        countryFields.corporateOfficeCountry
      );
      this.setCorporateRelatedPersonCitizenshipCountrySelect(
        countryFields.corporateRelatedPersonCitizenshipCountry
      );
      this.setCorporateRelatedPersonCountrySelect(
        countryFields.corporateRelatedPersonCountry
      );
    },

    setCorporateCountrySelect(currentValue = null) {
      Object.assign(this.corporateCountrySelect, {
        select: currentValue
          ? {
              text: this.countryCodes[currentValue],
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getCountryItems(this.countryCodes),
      });
    },

    setCorporateOfficeCountrySelect(currentValue = null) {
      Object.assign(this.corporateOfficeCountrySelect, {
        select: currentValue
          ? {
              text: this.countryCodes[currentValue],
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getCountryItems(this.countryCodes),
      });
    },

    setCorporateTradePurposeSelect(currentValue = null) {
      const tradePurposeCodes = this.corporationAmlCodes
        .CORPORATE_TRADE_PURPOSE;
      Object.assign(this.corporateTradePurposeSelect, {
        select: currentValue
          ? {
              text: tradePurposeCodes[currentValue],
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getTradePurposeItems(tradePurposeCodes),
      });
    },

    setCorporateFundSourceSelect(currentValue = null) {
      const fundSourceCodes = this.corporationAmlCodes.CORPORATE_FUND_SOURCE;
      Object.assign(this.corporateFundSourceSelect, {
        select: currentValue
          ? {
              text: fundSourceCodes[currentValue],
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getFundSourceItems(fundSourceCodes),
      });
    },

    setCorporateOwnerSkipTypeRadios() {
      this.corporateOwnerSkipTypeRadios = getCorporateOwnerSkipTypeItems(
        this.corporationAmlCodes.CORPORATE_OWNER_SKIP_TYPE
      );
    },

    setCorporateOwnerTypeRadios() {
      this.corporateOwnerTypeRadios = getCorporateOwnerTypeItems(
        this.corporationAmlCodes.CORPORATE_OWNER_TYPE
      );
    },

    setUnknownOwnerSkipType() {
      this.isUnknownOwnerSkipType =
        this.corporateData.corporateInput.ownerSkipType === "05";
    },

    checkCorporateOwnerSkipTypeRadios() {
      Object.assign(this.corporateData.corporateInput, {
        ownerType: "00",
        ownerShareRate: "0",
      });
      this.setUnknownOwnerSkipType();
    },

    changeCorporateDataOccupationCategory(selectedValue) {
      this.corporateData.corporateInput.occupationCategory = selectedValue;
    },

    setCorporateOccupationCategorySelect(currentValue = null) {
      const occupationCategoryCodes = this.corporationAmlCodes
        .CORPORATE_OCCUPATION_CATEGORY;
      Object.assign(this.corporateOccupationCategorySelect, {
        select: currentValue
          ? {
              text: occupationCategoryCodes[currentValue],
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getOccupationCategoryItems(occupationCategoryCodes),
      });
    },

    clearNewCorporateData() {
      this.corporateData.corporateInput = {};
      this.initInputElements();
    },

    // TODO: 테스트 코드 제거
    setTestNewCorporateData() {
      console.log("SET TEST CREATED ITEM");
      this.corporateData.corporateInput = {
        nameKo: "(주)스트리미",
        nameEn: "Streami. Inc.",
        type: "1",
        status: "1",

        identifier: "432-87-00120",
        corporateIdentifier: "110111-5790236",
        establishedDate: "2015-07-28",
        establishedCountry: "KR",

        country: "KR",
        zipcode: "11111",
        address: "서울 송파구 올림픽로35다길 42",
        addressDetail: "18층",
        phoneNumber: "02-561-2266",
        faxNumber: "02-561-2267",

        officeCountry: "KR",
        officeZipcode: "22222",
        officeAddress: "서울 송파구 올림픽로35다길 42",
        officeAddressDetail: "18층",
        officePhoneNumber: "02-561-2266",
        officeFaxNumber: "02-561-2267",

        // ownerSkipType: "0",
        // ownerType: "2",
        ownerShareRate: "30",
        isVirtualAssetProvider: true,

        tradePurpose: "07",
        fundSource: "B01",
        occupationCategory: "K64",
        occupationCategoryDetail: "ABCDE",
        establishedPurpose: "거래소",

        homepageUrl: "https://www.gopax.co.kr/aboutus",
        remark: "테스트 법인 정보 입니다.",
      };

      this.setCorporateTypeSelect(this.corporateData.corporateInput.type);
      this.setCorporateStatusSelect(this.corporateData.corporateInput.status);
      this.setCorporateCountrySelect(this.corporateData.corporateInput.country);
      this.setCorporateOfficeCountrySelect(
        this.corporateData.corporateInput.officeCountry
      );
      this.setCorporateTradePurposeSelect(
        this.corporateData.corporateInput.tradePurpose
      );
      this.setCorporateFundSourceSelect(
        this.corporateData.corporateInput.fundSource
      );
      this.setCorporateOccupationCategorySelect(
        this.corporateData.corporateInput.occupationCategory
      );
    },
    // END script : corporate

    // START script : corporate related person
    async loadCorporateRelatedPersons(corporateId) {
      this.loadingDialog = true;
      try {
        const requestUrl = `/api/corporate/${corporateId}/related-person`;
        const res = await this.authGet(requestUrl);
        this.relatedPersonData.corporateId = corporateId;
        this.relatedPersonItems = res.data.data;
        this.loadingDialog = false;
      } catch (e) {
        this.processError(e);
      }
    },

    async openRelatedPersonList(corporateId) {
      this.dialogRelatedPersonForm = false;
      this.dialogRelatedPersonDelete = false;
      this.dialogRelatedPersonList = true;
      await this.loadCorporateRelatedPersons(corporateId);
    },

    async loadCorporateRelatedPerson(corporateId, corporateRelatedPersonId) {
      try {
        const requestUrl = `/api/corporate/${corporateId}/related-person/${corporateRelatedPersonId}`;
        const res = await this.authGet(requestUrl);
        // console.log("in loadItem,", res.data.corporateRelatedPerson);
        Object.assign(this.relatedPersonData, {
          corporateId,
          corporateRelatedPersonId,
          relatedPersonInput: res.data.corporateRelatedPerson,
        });

        this.setCorporateRelatedPersonTypeSelect(
          this.relatedPersonData.relatedPersonInput.type
        );

        this.setCountryCodes({
          corporateRelatedPersonCitizenshipCountry: this.relatedPersonData
            .relatedPersonInput.citizenshipCountry,
          corporateRelatedPersonCountry: this.relatedPersonData
            .relatedPersonInput.country,
        });
      } catch (e) {
        this.processError(e);
      }
    },

    changeCorporateRelatedPersonType(selectedValue) {
      this.relatedPersonData.relatedPersonInput.type = selectedValue;
    },

    changeCorporateRelatedPersonCitizenshipCountry(selectedValue) {
      this.relatedPersonData.relatedPersonInput.citizenshipCountry = selectedValue;
    },

    changeCorporateRelatedPersonCountry(selectedValue) {
      this.relatedPersonData.relatedPersonInput.country = selectedValue;
    },

    setCorporateRelatedPersonTypeSelect(currentValue = null) {
      const select = currentValue
        ? {
            text: renderCorporateRelatedPersonType(currentValue),
            value: currentValue.toString(), // CAUTION! type should be string
          }
        : {};
      Object.assign(this.corporateRelatedPersonTypeSelect, {
        select,
        items: getCorporateRelatedPersonTypeItems(),
      });
    },

    setCorporateRelatedPersonCitizenshipCountrySelect(currentValue = null) {
      Object.assign(this.corporateRelatedPersonCitizenshipCountrySelect, {
        select: currentValue
          ? {
              text: this.countryCodes[currentValue],
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getCountryItems(this.countryCodes),
      });
    },

    setCorporateRelatedPersonCountrySelect(currentValue = null) {
      Object.assign(this.corporateRelatedPersonCountrySelect, {
        select: currentValue
          ? {
              text: this.countryCodes[currentValue],
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getCountryItems(this.countryCodes),
      });
    },

    clearNewRelatedPersonForm() {
      this.relatedPersonData.relatedPersonInput = {};
      this.initInputElements();
    },

    // TODO: 테스트 코드 제거
    setTestRelatedPersonForm() {
      console.log("SET TEST RELATED PERSON FORM");
      Object.assign(this.relatedPersonData, {
        relatedPersonInput: {
          type: 1,
          nameKo: "고파쓰",
          nameEn: "GOPASS",
          identifier: "123456-1234567",
          email: "gopass@streami.co",
          phoneNumber: "123-4567-8901",
          birthdate: "1999-09-09",
          gender: "1",
          citizenshipCountry: "KR",

          zipcode: "11111",
          address: "서울 송파구 올림픽로35다길 42",
          addressDetail: "18층",
          country: "KR",
        },
      });

      this.setCorporateRelatedPersonTypeSelect(
        this.relatedPersonData.relatedPersonInput.type
      );
      this.setCorporateRelatedPersonCitizenshipCountrySelect(
        this.relatedPersonData.relatedPersonInput.citizenshipCountry
      );
      this.setCorporateRelatedPersonCountrySelect(
        this.relatedPersonData.relatedPersonInput.country
      );
    },

    openCorporateRelatedPersonCreateForm() {
      try {
        this.relatedPersonData = {
          corporateId: this.relatedPersonData.corporateId,
          corporateRelatedPersonId: null,
          relatedPersonInput: {},
        };
        this.initInputElements("OPEN CORPORATE RELATED PERSON CREATE FORM");
      } catch (e) {
        this.processError(e);
      }
      this.dialogRelatedPersonForm = true;
      this.dialogRelatedPersonList = false;
    },

    async openCorporateRelatedPersonEditForm(corporateRelatedPersonId) {
      const corporateId = this.relatedPersonData.corporateId;
      try {
        await this.loadCorporateRelatedPerson(
          corporateId,
          corporateRelatedPersonId
        );
      } catch (e) {
        this.processError(e);
      }
      this.dialogRelatedPersonForm = true;
      this.dialogRelatedPersonList = false;
    },

    async storeCorporateRelatedPerson() {
      const corporateId = this.relatedPersonData.corporateId;
      try {
        const formData = {
          corporateId,
          relatedPersonInput: this.relatedPersonData.relatedPersonInput,
        };

        // console.log("before store,", formData);
        const res = await this.authPost(
          `/api/corporate/${corporateId}/related-person`,
          formData
        );
        // console.log("after store,", res);
        if (res.data.result) {
          await this.openRelatedPersonList(corporateId);
          this.showNotification("Stored");
        }
      } catch (e) {
        this.processError(e);
      }
    },

    async updateCorporateRelatedPerson() {
      const corporateId = this.relatedPersonData.corporateId;
      try {
        const corporateRelatedPersonId = this.relatedPersonData
          .corporateRelatedPersonId;
        const formData = {
          relatedPersonInput: this.relatedPersonData.relatedPersonInput,
        };

        // console.log("before update,", corporateRelatedPersonId, formData);
        const res = await this.authPut(
          `/api/corporate/${corporateId}/related-person/${corporateRelatedPersonId}`,
          formData
        );
        // console.log("after update,", res);
        if (res.data.result) {
          await this.openRelatedPersonList(corporateId);
          this.showNotification("Updated");
        }
      } catch (e) {
        this.processError(e);
      }
    },

    async resetRelatedPersonData() {
      const corporateId = this.relatedPersonData.corporateId;
      const corporateRelatedPersonId = this.relatedPersonData
        .corporateRelatedPersonId;
      await this.loadCorporateRelatedPerson(
        corporateId,
        corporateRelatedPersonId
      );
    },

    openCorporateRelatedPersonDeleteForm(corporateRelatedPersonId) {
      this.relatedPersonData.corporateRelatedPersonId = corporateRelatedPersonId;
      this.dialogRelatedPersonDelete = true;
      this.dialogRelatedPersonList = false;
    },

    closeCorporateRelatedPersonDeleteForm() {
      this.dialogRelatedPersonDelete = false;
      this.dialogRelatedPersonList = true;
    },

    async deleteCorporateRelatedPerson() {
      const corporateId = this.relatedPersonData.corporateId;
      try {
        const corporateRelatedPersonId = this.relatedPersonData
          .corporateRelatedPersonId;
        // console.log("before delete,", corporateRelatedPersonId);
        const res = await this.authDelete(
          `/api/corporate/${corporateId}/related-person/${corporateRelatedPersonId}`
        );
        // console.log("after delete,", res);
        if (res.data.result) {
          this.showNotification("Deleted");
          this.openRelatedPersonList(corporateId);
        }
      } catch (e) {
        this.processError(e);
      }
    },
    // END script : corporate related person

    // START script : user corporate
    async searchUserId(userId) {
      try {
        const requestData = { userId };
        console.log("before search userId,", requestData);
        const res = await this.callGrpcPost(
          "Admin",
          "FindUserForAddCorporate",
          JSON.stringify(requestData)
        );
        const parsedData = JSON.parse(res.data.data);
        console.log("after search userId, (1)", parsedData);
        if (parsedData.result) {
          const resultSearch = JSON.parse(parsedData.data);
          console.log("after search userId, (2)", resultSearch);
          return resultSearch;
        }
        return {
          isExist: false,
          isAlreadyUserCorporate: false,
          isCorp: false,
        };
      } catch (e) {
        this.processError(e);
      }
    },

    async checkUserId() {
      const {
        isExist,
        isAlreadyUserCorporate,
        email,
        isCorp,
      } = await this.searchUserId(this.userCorporateForRegistration.userId);
      if (!isExist) {
        this.showNotification("Error user not found");
        this.corporateData.userId = "";
        Object.assign(this.userCorporateForRegistration, { email: "" });
      } else if (isAlreadyUserCorporate) {
        this.showNotification("Error already registered corporation");
        this.corporateData.userId = "";
        Object.assign(this.userCorporateForRegistration, { email: "" });
      } else if (isCorp) {
        this.corporateData.userId = this.userCorporateForRegistration.userId;
        Object.assign(this.userCorporateForRegistration, { email });
      } else {
        console.log("in checkUserId, new account");
        this.openCorporateAccountList(this.userCorporateForRegistration.userId);
      }
    },

    async checkUserIdForUpdate() {
      const {
        isExist,
        isAlreadyUserCorporate,
        email,
        isCorp,
      } = await this.searchUserId(this.userCorporateForAddition.userId);
      if (!isExist) {
        this.showNotification("Error user not found");
        this.userCorporateForAddition = {};
      } else if (isAlreadyUserCorporate) {
        this.showNotification("Error already registered corporation");
        this.userCorporateForAddition = {};
      } else if (isCorp) {
        Object.assign(this.userCorporateForAddition, { email });
        this.dialogSearchUser = true;
      } else {
        console.log("in checkUserIdForUpdate, new account");
        this.openCorporateAccountList(this.userCorporateForAddition.userId);
      }
    },

    openConfirmAppendUserCorporate() {
      this.$swal
        .fire({
          icon: "question",
          title: this.$t("corporate-user-append-description"),
          keydownListenerCapture: true,
          showCancelButton: true,
          confirmButtonText: this.$t("button-text.append"),
          cancelButtonText: this.$t("cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.appendUserCorporate();
          }
        });
    },

    async appendUserCorporate() {
      const { userId, corporateAccountId } = this.userCorporateForAddition;
      const corporateId = this.corporateData.corporateId;
      try {
        const requestData = { userId, corporateId, corporateAccountId };
        const requestUrl = `/api/corporate/append-user-corporate`;
        console.log(
          "before add userId to user corporeate,",
          requestUrl,
          requestData
        );

        const res = await this.authPost(requestUrl, requestData);
        // console.log("after add userId to user corporeate,", res);
        if (res.data.result) {
          this.showNotification("Success registration");
          await this.loadCorporate(this.corporateData.corporateId);
        } else {
          this.showNotification(res.data);
        }
      } catch (e) {
        this.processError(e);
      }
      this.userCorporateForAddition = {};
    },

    openConfirmRemoveUserCorporate(id) {
      this.$swal
        .fire({
          icon: "question",
          title: this.$t("confirm-remove"),
          text: this.$t("corporate-user-remove-description"),
          keydownListenerCapture: true,
          showCancelButton: true,
          confirmButtonText: this.$t("remove"),
          cancelButtonText: this.$t("cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.removeUserCorporate(id);
          }
        });
    },

    async removeUserCorporate(id) {
      try {
        const requestData = { id };
        console.log("before remove user corporeate,", requestData);
        const res = await this.authPut(
          `/api/corporate/remove-user-corporate`,
          requestData
        );
        console.log("after update,", res);
        if (res.data.errorCode) {
          this.showNotification(
            `${"Fail remove"} ${res.data.errorCode} ${res.data.errorMsg}`
          );
        } else if (res.data.result) {
          this.showNotification("Success remove");
          await this.loadCorporate(this.corporateData.corporateId);
        } else {
          this.showNotification(res.data.data);
        }
      } catch (e) {
        this.processError(e);
      }
      this.userCorporateForAddition = {};
    },
    // END script : user corporate

    // START script : corporate account
    async openCorporateAccountList(userId) {
      await this.loadCorporateAccountList(userId);
    },

    async loadCorporateAccountList(userId) {
      this.corporateAccountItems = [];
      try {
        const requestData = { id: userId };
        console.log("before load corporate account,", requestData);
        const res = await this.callGrpcPost(
          "Admin",
          "GetCorporateAccount",
          JSON.stringify(requestData)
        );
        const parsedData = JSON.parse(res.data.data);
        console.log(
          "after load corporate account,",
          parsedData,
          parsedData.length
        );

        if (parsedData.data) {
          this.corporateAccountItems = parsedData.data;
          this.dialogCorporateAccountList = true;
        } else {
          this.showNotification(
            `${this.$t("page.request")} ${this.$t("none")}`
          );
          this.dialogCorporateAccountList = false;
        }
      } catch (e) {
        this.processError(e);
      }
    },

    async selectCorporateAccount(item) {
      // console.log("SELECT CORPORATE ACCOUNT, (1)", JSON.stringify({ item }));

      Object.assign(this.corporateData, {
        userId: item.userId,
        corporateAccountId: item.id,
      });
      // console.log(
      //   "SELECT CORPORATE ACCOUNT, (2)",
      //   JSON.stringify({ corporateData: this.corporateData })
      // );

      if (this.isRegistration) {
        Object.assign(this.userCorporateForRegistration, {
          email: item.user.email,
        });
      } else {
        Object.assign(this.userCorporateForAddition, {
          corporateAccountId: item.id,
        });
        this.openConfirmAppendUserCorporate();
      }
      // console.log(
      //   "SELECT CORPORATE ACCOUNT, (3)",
      //   JSON.stringify({
      //     r: this.userCorporateForRegistration,
      //     a: this.userCorporateForAddition,
      //   })
      // );

      this.corporateAccountItems = [];
      this.dialogCorporateAccountList = false;
    },
    // END script : corporate account

    // START script : corporate document
    async openUploadDocumentForm(corporateId) {
      this.clearFiles();

      console.log("UPLOAD DOCUMENT", corporateId);
      Object.assign(this.corporateData, { corporateId });
      this.dialogDocument = true;
      await this.loadCorporateDocuments(corporateId);
    },

    async loadCorporateDocuments(corporateId) {
      this.loadingDialog = true;
      this.corporateDocumentItems = [];
      try {
        const requestUrl = `/api/corporate/${corporateId}/documents`;
        const res = await this.authGet(requestUrl);
        // console.log("res.data", res.data);
        if (res.data.result) {
          const parsedData = JSON.parse(res.data.data);
          console.log(
            "after load corporate documents,",
            parsedData,
            parsedData.length
          );

          this.corporateDocumentItems = parsedData;
        } else {
          this.showNotification(
            `${this.$t("page.request")} ${this.$t("none")}`
          );
        }
        this.loadingDialog = false;
      } catch (e) {
        this.processError(e);
      }
    },

    async clearFiles() {
      this.files = [];
      this.setCorporateDocumentTypeSelect();
    },

    async uploadDocument(corporateId) {
      this.inProgressUpload = true;
      try {
        const formData = new FormData();

        const corporateDocumentType = this.corporateDocumentTypeSelect.select
          .value;
        const files = this.files;
        if (!corporateDocumentType) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("corporate-document.error-select-type"),
            keydownListenerCapture: true,
            confirmButtonText: this.$t("confirm"),
          });
          this.inProgressUpload = false;
          return false;
        }

        if (!files || files.length === 0) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("corporate-document.error-select-file"),
            keydownListenerCapture: true,
            confirmButtonText: this.$t("confirm"),
          });
          this.inProgressUpload = false;
          return false;
        }

        console.log("in uploadDocument, (1)", corporateDocumentType, files);

        // set files
        files.map((file, index) => {
          formData.append(`files`, file, file.name);
          console.log("in uploadDocument, (2)", index, file.name, file);
        });
        await console.log("in uploadDocument, (3)", files, formData);

        // set document type
        Object.assign(formData, {
          corporateDocumentType,
        });

        console.log(
          "in uploadDocument, (4), before upload,",
          typeof formData.corporateDocumentType,
          formData.corporateDocumentType,
          formData.getAll("files")
        );

        const res = await this.authPostWithFiles(
          `/api/corporate/${corporateId}/upload-documents/${corporateDocumentType}`,
          formData
        );

        console.log("in uploadDocument, (5), after upload,", res.data);
        if (res.data.result) {
          this.showNotification("Uploaded");
          this.loadCorporateDocuments(corporateId);
        }
        this.inProgressUpload = false;
        this.clearFiles();
      } catch (e) {
        this.processError(e);
        this.inProgressUpload = false;
      }
    },

    setCorporateDocumentTypeSelect(currentValue = null) {
      Object.assign(this.corporateDocumentTypeSelect, {
        select: currentValue
          ? {
              text: renderCorporateDocumentType(currentValue),
              value: currentValue.toString(), // CAUTION! type should be string
            }
          : {},
        items: getCorporateDocumentTypeItems(),
      });
    },

    openConfirmRemoveCorporateDocument(id) {
      this.$swal
        .fire({
          icon: "question",
          title: this.$t("confirm-remove"),
          text: this.$t("corporate-document-remove-description"),
          keydownListenerCapture: true,
          showCancelButton: true,
          confirmButtonText: this.$t("remove"),
          cancelButtonText: this.$t("cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.removeCorporateDocument(id);
          }
        });
    },

    async removeCorporateDocument(id) {
      try {
        const requestData = { id };
        console.log("before remove corporeate document,", requestData);
        const res = await this.authPut(
          `/api/corporate/remove-corporate-document`,
          requestData
        );
        console.log("after update,", res);
        if (res.data.errorCode) {
          this.showNotification(
            `${"Fail remove"} ${res.data.errorCode} ${res.data.errorMsg}`
          );
        } else if (res.data.result) {
          this.showNotification("Success remove");
          await this.loadCorporateDocuments(this.corporateData.corporateId);
        } else {
          this.showNotification(res.data.data);
        }
      } catch (e) {
        this.processError(e);
      }
    },

    async openAttachment(id) {
      console.log("in openAttachment,", id);
      try {
        const requestData = { id };
        const res = await this.callGrpcPost(
          "Admin",
          "GetCorporateDocumentUrl",
          JSON.stringify(requestData)
        );
        const parsedData = JSON.parse(res.data.data);
        if (parsedData.result) {
          const resultAttachment = JSON.parse(parsedData.data);
          const { signedUrl } = resultAttachment;
          console.log("signedUrl:", signedUrl);
          window.open(signedUrl, "_blank");
          return true;
        }
      } catch (e) {
        this.processError(e);
      }
    },
    // END script : corporate document

    // START script : approval
    openConfirmApproveCorporateAccount(
      userCorporateId,
      corporateAccountId,
      isFirst = false
    ) {
      console.log(
        "CONFIRM APPROVAL CORPORATE ACCOUNT",
        userCorporateId,
        corporateAccountId
      );
      this.$swal
        .fire({
          icon: "question",
          title: this.$t("confirm-approve"),
          keydownListenerCapture: true,
          showCancelButton: true,
          confirmButtonText: this.$t("button-text.approve"),
          cancelButtonText: this.$t("cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.openApproveCorporateAccount(
              userCorporateId,
              corporateAccountId,
              isFirst // to isAml
            );
          }
        });
    },

    onCheckApproval() {
      this.approveCorporateAccountResult = this.$t("in-progress");
      this.dialogCheckApproval = true;
      this.inProgressApproval = true;
    },

    async openApproveCorporateAccount(
      userCorporateId,
      corporateAccountId,
      isAml = false
    ) {
      this.onCheckApproval();

      try {
        this.approveCorporateAccountTitle = `${this.$t(
          "page.corporate-account-list"
        )} ${this.$t("button-text.approve")}`;

        const isApprove = Number(corporateAccountId) > 0;
        const requestData = { userCorporateId, isApprove, isAml };
        const requestUrl = `/api/corporate/approve-corporate-account`;
        console.log(
          "before approve corporate account,",
          requestUrl,
          requestData
        );

        const res = await this.authPost(requestUrl, requestData);
        console.log("after approve corporate account,", res.data.result);

        this.approveCorporateAccountResult = this.setApprovalResult(res);

        this.loadCorporate(this.corporateData.corporateId);
        this.inProgressApproval = false;
      } catch (e) {
        this.processError(e);
      }
    },

    setApprovalResult(res) {
      if (res.data.result) {
        return this.$t("completed");
      }
      return res.data.data;
    },

    openConfirmKycRefresh(id) {
      console.log("KYC REFRESH", id);
      this.$swal
        .fire({
          icon: "question",
          title: this.$t("confirm-kyc-refresh"),
          keydownListenerCapture: true,
          showCancelButton: true,
          confirmButtonText: this.$t("button-text.request"),
          cancelButtonText: this.$t("button-text.cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.requestKycRefresh(id);
          }
        });
    },

    onCheckRequest() {
      this.approveCorporateAccountResult = this.$t("in-progress");
      this.dialogCheckApproval = true;
      this.inProgressApproval = true;
    },

    async requestKycRefresh(userCorporateId) {
      this.onCheckRequest();
      try {
        this.approveCorporateAccountTitle = this.$t("kyc-refresh");

        const requestData = { userCorporateId };
        const requestUrl = `/api/corporate/request-corporate-kyc-refresh`;
        console.log("before request kyc refresh,", requestUrl, requestData);

        const res = await this.authPost(requestUrl, requestData);
        console.log("after request kyc refresh,", res.data.result);

        this.approveCorporateAccountResult = this.setApprovalResult(res);

        this.loadCorporate(this.corporateData.corporateId);
        this.inProgressApproval = false;
      } catch (e) {
        this.processError(e);
      }
    },
    // END script : approval
  },
};
</script>
